
import { createNamespacedHelpers } from 'vuex'
import product from '~/mixins/product'
import productPriceFrom from '~/mixins/productPriceFrom'
import userTypes from '~/store/user/types'
import GTM from '~/helpers/gtm'

const { mapGetters: mapGettersUser } = createNamespacedHelpers('user')

export default {
  name: 'MediTileProduct',
  mixins: [product, productPriceFrom],

  props: {
    product: {
      type: Object,
      required: true,
    },

    isDescription: {
      type: Boolean,
      default: false,
    },

    isBottomBtn: {
      type: Boolean,
      default: false,
    },

    isSquare: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isHover: false,
    }
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
      salesManagoId: userTypes.GET_SALESMANAGO_ID,
    }),

    productLink () {
      return this.product?.productSrc
        ? this.localePath(this.product.productSrc)
        : this.localePath({ name: 'p-slug', params: { slug: this.product.urlKey } })
    },

    getImagePlaceholder () {
      return this.product._typename === 'AppointmentWithDoctorProduct' || this.product._typename === 'AppointmentProduct'
        ? 'doctor_placeholder1.svg'
        : 'product_placeholder.svg'
    },

    getLabels () {
      const { promo, popular, newFlag, label } = this.product

      return [
        {
          name: 'label.new',
          value: newFlag,
        },
        {
          name: 'label.popular',
          value: popular,
        },
        {
          name: 'label.promotion',
          value: promo,
        },
        {
          name: label,
          value: !!label,
        },
      ]
    },

    hasVisibleLabels () {
      return this.getLabels.some(l => l.value)
    },

    detailsText () {
      return this.$config.storeCode === 'medicoverapteka' ? 'Explore the product' : 'Find out more'
    },

    price () {
      return this.isMediclubMember ? (this.minMediclubPrice || this.minRegularPrice) : this.minRegularPrice
    },
  },

  methods: {
    onProductTileClick () {
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push(GTM.trackProductSelectFromList('select_item', this.product, this.price, this.salesManagoId))

      this.$router.push(this.productLink)
    },

    handleHover (val) {
      this.isHover = val
    },
  },
}
